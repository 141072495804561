.awareness-container {
  background-color: #798645;
  padding: 80px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.awareness {
  max-width: 1700px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 100px;
}

.aware-head {
  width: 40%;
}

.aware-head ul {
  list-style: none; /* Removes the default bullets */
  padding: 0; /* Removes default padding for the UL */
  margin-left: 0; /* Aligns with your current styling */
  width: 90%;
}

.aware-head ul li {
  font-weight: 400;
  font-size: 1.2rem;
  position: relative; /* Ensures the emoji aligns properly */
  padding-left: 35px; /* Creates space for the emoji */
}

.aware-head ul li::before {
  content: "✨"; /* Adds the star emoji as a custom bullet */
  position: absolute;
  left: 0; /* Aligns the emoji to the left of the LI */
}

.aware-broll-vid {
  width: 45%;
  height: 600px;
  overflow: hidden;
  border-radius: 30px;
}

video {
  width: 100%;
  height: 100%; /* Ensures the video fills the container */
  object-fit: cover; /* Maintains aspect ratio while cropping excess */
  object-position: bottom;
}

@media (max-width: 850px) {
  .awareness {
    flex-direction: column;
  }
  .aware-head {
    width: 80%;
  }
  .aware-broll-vid {
    width: 80%;
  }
}

@media (max-width: 450px) {
  .awareness {
    gap: 30px;
  }
  .aware-broll-vid {
    height: 400px;
    width: 90%;
  }

  .aware-head {
    width: 90%;
  }
}
