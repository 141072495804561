.who-am-i {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100vh; /* Full height */
  overflow: hidden;
  background: linear-gradient(135deg, #798645, #f2eed7);
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  height: auto;
  z-index: 1; /* Send the video to the background */
  opacity: 0.72; /* Adjust opacity for overlay effect */
}

.content {
  max-width: 800px;
  width: 90%; /* Allow more flexibility for smaller screens */
  position: relative;
  z-index: 2; /* Ensure content is above the video */
  color: white;
  text-align: center;
  padding: 2rem;
}

.content p {
  font-size: 1.4rem;
  line-height: 1.8; /* Improve readability */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .who-am-i {
    height: auto; /* Allow content to define height */
    padding: 2rem 0;
  }

  .background-video {
    opacity: 0.6; /* Reduce opacity for better text readability on small screens */
  }

  .content {
    width: 95%; /* Wider content area for smaller screens */
    padding: 1.5rem;
  }

  .content p {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .content p {
    font-size: 1rem; /* Further adjust font size for very small screens */
  }
}
