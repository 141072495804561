/* popup.css */

.PopUp {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 530px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 60px 20px;
  z-index: 1000;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.PopUp h1 {
  color: #626f47;
  font-size: 2.5rem;
}

.PopUp h2 {
  font-size: 1.5rem;
  color: #798645;
  margin-bottom: 0px;
  margin-top: 3px;
  font-family: raleway, sans-serif;
  font-weight: 700;
  font-style: italic;
}

.PopUp p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  margin-top: 15px;
  font-weight: 900;
  color: #626f47;
}

.PopUp form {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.PopUp label {
  display: none;
}

.pop-up-form button {
  background-color: #798645;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: raleway, sans-serif;
  font-weight: 700;
  font-style: italic;
  text-align: center;
}

.PopUp button:hover {
  background-color: #626f47;
}

.PopUp input {
  width: 100%;
  font-family: raleway, sans-serif;
  font-weight: 700;
  font-style: italic;
  padding: 10px;
  border: none;
  background-color: #f2eed7;
  border-radius: 5px;
  font-size: 1rem;
}

.PopUp input:focus {
  outline: none; /* Highlight with a border on focus */
  background-color: #fefae0; /* Slightly lighter background */
  transition: background-color 0.3s, outline 0.3s;
}

.PopUp input:not(:placeholder-shown) {
  background-color: #798645; /* Change the background when the field has content */
  color: #fff; /* Ensure text remains readable */
  font-weight: 900; /* Add emphasis to typed content */
}

.PopUp input:not(:placeholder-shown)::placeholder {
  color: transparent; /* Hide the placeholder once text is typed */
}

.g-recaptcha {
  display: flex;
  justify-content: center;
}

.PopUp .close-btn {
  position: absolute;
  top: 15px;
  right: 20px;
  background-color: transparent;
  border: none;
  font-size: 1.4rem;
  cursor: pointer;
  color: #626f47;
  font-family: raleway, sans-serif;
  font-weight: 700;
  font-style: italic;
}

/* For backdrop overlay */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.2); */
  z-index: 999;
  backdrop-filter: blur(3px);
}
