.credibility-container {
  background-color: #fefae0;
  padding: 100px 0px;
  text-align: center;
}

.credibility-title {
  font-size: 2.7rem;
  color: #798645;
  margin: 0px;
}

.credibility-container h2 {
  color: #798645;
  margin-top: 5px;
  margin-bottom: 40px;
}

.carousel-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  position: relative;
}

.carousel-row {
  display: flex;
  gap: 20px;
}

.carousel-row.reverse {
  height: auto;
  display: flex;
  justify-content: end;
  align-items: end;
}

.carousel-row::after {
  content: "";
  display: flex;
  justify-content: end;
  align-items: end;
}

.carousel-item {
  background: white;
  border-radius: 12px;
  padding: 20px;
  min-width: 250px;
  flex: 0 0 auto;
  text-align: center;
  transition: transform 0.3s ease;
}

.carousel-item:hover {
  transform: scale(1.02);
}

.credibility-stat {
  font-size: 1.5rem;
  font-weight: bold;
  color: #798645;
  margin-bottom: 10px;
}

.credibility-description,
.testimonial-feedback {
  font-size: 1rem;
  color: #798645;
}

.testimonial-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .carousel-container {
    gap: 10px;
  }

  .carousel-item {
    min-width: 200px;
  }

  .credibility-stat {
    font-size: 1.2rem;
  }

  .credibility-description,
  .testimonial-feedback {
    font-size: 0.9rem;
  }
}
