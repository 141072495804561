.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background: #798645; /* Replace with your brand color */
  color: white;
}

.footer-left {
  display: flex;
  align-items: center;
}

.footer-logo {
  width: 100px; /* Adjust size as needed */
  margin-right: 20px;
}

.footer-text {
  font-size: 0.9rem;
  color: #ddd;
}

.footer-right {
  text-align: right;
}

.footer-right p {
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.footer-socials {
  display: flex;
  justify-content: end;
  align-items: end;
  gap: 15px;
}

.footer-socials a {
  color: white;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.footer-socials a:hover {
  color: #f2eed7; /* Replace with hover color */
}

@media (max-width: 500px) {
  footer {
    flex-direction: column;
  }
}
