.endgame-container {
  padding: 0px 10px 150px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #626f47;
  color: white;
}

.endgame {
  padding: 50px;
  text-align: center;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.endgame p {
  max-width: 900px;
  font-size: 1.3rem;
}

.endgame-steps {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.step {
  background: white;
  border-radius: 12px;
  padding: 20px;
  flex: 1 1 calc(30% - 20px); /* 3-column grid */
  max-width: 400px;
  color: #798645;
  transition: transform 0.3s ease;
}

.step h2 {
  font-weight: 900;
  font-size: 1.6rem;
}

.step:hover {
  transform: translateY(-5px);
}

.step-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.step-description {
  font-size: 1rem;
  line-height: 1.4;
}

@media (max-width: 650px) {
  .endgame p {
    font-size: 1rem;
  }
}

@media (max-width: 450px) {
  .endgame {
    padding: 10px;
  }
}
