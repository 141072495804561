/* Main Quiz Background */
.quiz-container {
  background-color: #798645;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -100;
}

/* Container for Quiz Content */
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px; /* Added padding for smaller screens */
  box-sizing: border-box; /* Ensures padding is included in width/height */
}

.container h1 {
  font-size: 3rem; /* Reduced size for better responsiveness */
  text-align: center;
  line-height: 1.2;
  margin-bottom: 20px;
}

/* Quiz Box */
.schedule-quiz {
  color: #5c6e3d;
  padding: 20px; /* Reduced padding for smaller screens */
  border-radius: 30px;
  background-color: #f2eed7;
  max-width: 600px;
  width: 100%; /* Ensure full width on smaller devices */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  text-align: center;
  font-family: Arial, sans-serif;
}

/* Progress Bar */
.progress-bar {
  width: 90%;
  background-color: #fefae0;
  height: 10px;
  border-radius: 5px;
  margin: 0 auto;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: #798645;
  transition: width 0.3s ease;
}

/* Option Button */
.option-button {
  display: block;
  background-color: #798645;
  color: white;
  padding: 12px 20px; /* Increased padding for better tap targets */
  margin: 10px auto;
  border: none;
  border-radius: 8px; /* Softer edges */
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s;
}

.option-button:hover {
  background-color: #626f47;
}

.results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.results input {
  text-align: center;
  width: 50%;
  font-family: raleway, sans-serif;
  font-weight: 700;
  font-style: italic;
  padding: 10px;
  border: none;
  background-color: white;
  border-radius: 5px;
  font-size: 1rem;
}

.results input:focus {
  outline: none; /* Highlight with a border on focus */
}

.results input:not(:placeholder-shown) {
  background-color: #798645; /* Change the background when the field has content */
  color: #fff; /* Ensure text remains readable */
  font-weight: 900; /* Add emphasis to typed content */
}

.results input:not(:placeholder-shown)::placeholder {
  color: transparent; /* Hide the placeholder once text is typed */
}

/* Results Link/Button */
.results a {
  margin-top: 40px;
  display: inline-block;
  color: white;
  font-size: 1.2rem;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.results a:hover {
  background-color: white;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .container h1 {
    font-size: 2.5rem; /* Reduce title size for tablets */
  }

  .schedule-quiz {
    padding: 15px; /* Adjust padding for smaller screens */
    border-radius: 20px; /* Smaller border radius */
  }

  .option-button {
    font-size: 1.1rem; /* Slightly smaller font size */
    padding: 10px 15px; /* Adjust padding */
  }
}

@media (max-width: 480px) {
  .container h1 {
    font-size: 2rem; /* Further reduce title size for phones */
  }

  .schedule-quiz {
    padding: 10px; /* Further adjust padding */
    border-radius: 15px;
  }

  .option-button {
    font-size: 1rem; /* Smaller font size for phones */
    padding: 8px 10px; /* Adjust padding for smaller devices */
  }
}
